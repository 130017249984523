body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button-right
{
  float: right;
  clear: both;
}

.blog {
  width:100%;
  float: left;
  margin: 5px 0;
}
.blog ul {
  width:100%;
  display: block;
  margin: 0 0 30px;
  float: left;
  /* padding: 0; */
}

.blog h3 {
  font-weight: 500;
  margin-bottom: 15px;
}

.heading-set
{
  text-decoration: none;
  color: rgb(31, 29, 29);
}

.margin-top
{
  margin-top: 30px
}

/* .btn-success:hover {
  color: none;
  background-color: none !important;
  border-color: none  !important;
} */

/* .btn-success {
  color: #fff;
  background-color: none;
  border-color: none;
} */

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #80c9ee !important;
  border-color: #80c9ee !important;
}

#commentBody
{
  height: 50px;
  width: 80%;
  padding:10px;
  display:block;
  border-radius:5px;
  border:1px solid #3583aa;
}

#commentName
{
  width: 80%;
  width: 80%;
  padding: 10px;
  display:block;
  border-radius:5px;
  border:1px solid #3583aa;
}
/*
#comment-button
{
  width: 20px
} */

#comment-button
{
  background-color: #3583aa;
  border: none;
  color: white;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  box-shadow: 0 10px 30px 0 rgba(95,186,233,0.4);
  border-radius: 5px 5px 5px 5px;
  margin: 5px 20px 40px 20px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}


.green-background {
  background-color: #0a8012;
  color: #fff;
}

body, html {
  background: #3298dc !important;
  min-height: 100%;
}

body {
  padding-bottom: 20px;
}

.navbar {
  margin-bottom: 20px;
  display: block !important;
  padding: 0 !important;
}

.navbar a:hover {
  text-decoration: none;
}

.navbar .container, .navbar .container-fluid, .navbar .container-lg, .navbar .container-md, .navbar .container-sm, .navbar .container-xl {
  display: block !important;
  padding: 0 !important;
}

.navbar-brand {
  display: flex !important;
  margin-right: 0 !important;
}

.accordion>.card {
  margin-bottom: 0px;
}

@keyframes blink {
  0% { opacity: 0.7; }
  40% { opacity: 0.9; }
  50% { opacity: 1; }
  60% { opacity: 0.9; }
  100% { opacity: 0.7; }
}
.skeleton-blink {
  animation: blink 1s infinite;
}
.skeleton-blink article {
  min-height: 400px;
}
.skeleton-blink article.header, .skeleton-blink article.footer {
  min-height: 70px;
}
footer.footer a {
  color: #17386d !important;
}

.nav-tabs {
  background-color: white;
  padding-left: 5px !important;
  padding-top: 5px !important;
}

.tab-content>.active {
  background-color: white;
}

.modal-card, .modal-content {
  margin: 0;
}
